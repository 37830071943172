const teamDataTwo = [
  {
    id: 1,
    imgSrc: require("../../../assets/images/teams/Urmi Veera.jpg"),
    tname: "Urmi Veera",
    designation: "Chief Human Resources Officer ",
    style: "md:mt-[-50px]",
  },
  {
    id: 2,
    imgSrc: require("../../../assets/images/teams/Anjesh Kushwaha.jpg"),
    tname: "Anjesh Kushwaha",
    designation: "Chief Web Developer Officer ",
    style: "md:mt-[-50px]",
  },
  {
    id: 3,
    imgSrc: require("../../../assets/images/teams/Aneesha Sanil.jpg"),
    tname: "Aneesha Sanil",
    designation: "Graphic Designer Officer",
    style: "md:mt-[0px]",
  },
  {
    id: 4,
    imgSrc: require("../../../assets/images/teams/Yash Jadav.jpg"),
    tname: "Yash Jadav",
    designation: "Graphic Designer Officer",
    style: "md:mt-[0px]",
  },
  {
    id: 5,
    imgSrc: require("../../../assets/images/teams/Kedar Agare.jpg"),
    tname: "Kedar Agare",
    designation: "Video Editer Officer",
    style: "md:mt-[0px]",
  },
  {
    id: 6,
    imgSrc: require("../../../assets/images/teams/Tejas Adhikari.jpg"),
    tname: "Tejas Adhikari",
    designation: "Web Developer Officer",
    style: "md:mt-[0px]",
  },
  {
    id: 7,
    imgSrc: require("../../../assets/images/teams/Vinal Mehta.jpg"),
    tname: "Vinal Mehta",
    designation: "Chief Content Officer ",
    style: "md:mt-[0px]",
  },
];
export default teamDataTwo;
