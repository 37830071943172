import React from 'react'
// import BlogBlur from './components/pagesSection/blogs/BlogBlur'
// import BlogHero from './components/pagesSection/blogs/BlogHero'
import BlogsCol from './components/pagesSection/blogs/BlogsCol'
// import BlogsGrid from './components/pagesSection/blogs/BlogsGrid'

const Blogs = () => {
    return (
        <>
            {/* <BlogHero /> */}
            {/* <BlogsGrid /> */}
            <BlogsCol />
            {/* <BlogBlur /> */}
        </>
    )
}

export default Blogs