import React, { useEffect, useLayoutEffect } from "react";
// import Navbar from "./components/Navbar";
import Home from "./home";
import Contact from "./contact";
import About from "./about";
import Portfolio from "./portfolio";
import JoinTeamForm from "./joinTeamForm";
import Error from "./error";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Services from "./services";
import GoToTop from "./components/GoToTop";
import Footer from "./components/Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import OnPageChange from "./components/OnPageChange";
import TermsAndConditions from "./TermsAndConditions";
import Blogs from "./Blogs";
import SinglePost from "./components/pagesSection/blogs/post/SinglePost";
// Blogs
import ContentWritingBlog from "./components/pagesSection/blogs/post/ContentWritingBlog";
import LeadGenerationBlog from "./components/pagesSection/blogs/post/LeadGenerationBlog";
import SinglePortfolio from "./components/pagesSection/portfolio/Hearmo";
import GraphicDesigning from "./components/pagesSection/services/GraphicDesigning";
import Branding from "./components/pagesSection/services/Branding";
import WebDevelopment from "./components/pagesSection/services/WebDevelopment";
import SocialMedia from "./components/pagesSection/services/SocialMedia";
import ContentWriting from "./components/pagesSection/services/ContentWriting";
import DigitalMedia from "./components/pagesSection/services/DigitalMedia";
// Protfolios
import Rupiya from "./components/pagesSection/portfolio/Rupiya";
import Mitticool from "./components/pagesSection/portfolio/Mitticool";
import Mmpl from "./components/pagesSection/portfolio/Mmpl";
import Hearmo from "./components/pagesSection/portfolio/Hearmo";
import Siddha from "./components/pagesSection/portfolio/Siddha";
import GuruRandhawa from "./components/pagesSection/portfolio/GuruRandhawa";
import { Helmet } from "react-helmet";
// Fluid Cursur
// Parallax
import { ParallaxProvider } from "react-scroll-parallax";
import OmMouldings from "./components/pagesSection/portfolio/OmMouldings";
import Namah from "./components/pagesSection/portfolio/Namah";
import Roha from "./components/pagesSection/portfolio/Roha";
import EagleExpress from "./components/pagesSection/portfolio/EagleExpress";
import Batwa from "./components/pagesSection/portfolio/Batwa";
import Amrut from "./components/pagesSection/portfolio/Amrut";
import Nirvana from "./components/pagesSection/portfolio/Nirvana";
import Nilam from "./components/pagesSection/portfolio/Nilam";
import Samarpan from "./components/pagesSection/portfolio/Samarpan";
import TheWineLeaf from "./components/pagesSection/portfolio/TheWineLeaf";
import Dunamis from "./components/pagesSection/portfolio/Dunamis";
import WhiteNirvana from "./components/pagesSection/portfolio/WhiteNirvana";
import Header from "./components/header";
import Blank from "./components/Blank";

function App() {
  // on Back Page Reload
  window.addEventListener("popstate", (event) => {
    window.location.reload();
    window.location.reload();
  });

  useEffect(() => {
    // document.getElementById(
    //   "scrollToTop"
    // ).innerHTML = `<div id="scrollButton" class="top-btn text-xl w-[60px] h-[60px] text-white bg-gradient-to-r from-[#80652e] to-[#f6d98d] rounded-full fixed sm:bottom-6 sm:right-6 bottom-4 right-4 z-50 flex justify-center items-center text-center cursor-pointer">
    //                 <BsArrowUp className="top-btn--icon" />
    //             </div>`;
    // const scrollButton = document.getElementById("scrollButton");
    // scrollButton.addEventListener("click", function () {
    //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // });
  }, []);

  return (
    <>
      <div style={{ position: "relative" }}>
        <ParallaxProvider>
          <div className="" style={{ position: "relative" }}>
            <Helmet>
              <title>
                Digital Marketing Agency &amp; Company In Mumbai |Aghori Media
                House
              </title>
              <meta
                name="description"
                content="Aghori Media House is a leading digital marketing agency having professional expertise in branding, marketing, website development services and social media.
        "
              />
            </Helmet>
            <Header />

            <BrowserRouter>
              <OnPageChange />
              {/* <NavbarTwo /> */}

              <Routes>
                <Route path="/" element={<Home className="home" />} />
                <Route exact path="/identity" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/portfolio" element={<Portfolio />} />
                <Route exact path="/expertis" element={<Services />} />
                <Route exact path="/joinTeamForm" element={<JoinTeamForm />} />
                <Route path="/blogs" element={<Blogs />} />
                {/* Blogs */}
                <Route
                  path="/blogs/benefits-of-content-writing"
                  element={<ContentWritingBlog />}
                />
                <Route
                  path="/blogs/Lead-Generation"
                  element={<LeadGenerationBlog />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-and-condition"
                  element={<TermsAndConditions />}
                />
                <Route path="/single-post" element={<SinglePost />} />
                {/* Portfolios */}
                <Route path="/single-portfolio" element={<SinglePortfolio />} />
                <Route path="/portfolio/rupiya" element={<Rupiya />} />
                <Route path="/portfolio/mitticool" element={<Mitticool />} />
                <Route path="/portfolio/siddha" element={<Siddha />} />
                <Route path="/portfolio/roha-group" element={<Roha />} />
                <Route
                  path="/portfolio/om-mouldings"
                  element={<OmMouldings />}
                />
                <Route path="/portfolio/enfuse" element={<Mitticool />} />
                <Route path="/portfolio/namah" element={<Namah />} />
                <Route path="/portfolio/nilam" element={<Nilam />} />
                <Route path="/portfolio/mmpl" element={<Mmpl />} />
                <Route path="/portfolio/hearmo" element={<Hearmo />} />
                <Route path="/portfolio/samarpan" element={<Samarpan />} />
                <Route
                  path="/portfolio/the-wine-leaf"
                  element={<TheWineLeaf />}
                />
                <Route
                  path="/portfolio/eagle-express"
                  element={<EagleExpress />}
                />
                <Route path="/portfolio/batwa" element={<Batwa />} />
                <Route path="/portfolio/dunamis" element={<Dunamis />} />
                <Route path="/portfolio/amrut-masala" element={<Amrut />} />
                <Route path="/portfolio/nirvana-group" element={<Nirvana />} />
                <Route
                  path="/portfolio/guru-randhawa"
                  element={<GuruRandhawa />}
                />
                <Route
                  path="/portfolio/white-nirvana"
                  element={<WhiteNirvana />}
                />
                {/* Services */}
                <Route
                  path="/expertis/graphic-designing"
                  element={<GraphicDesigning />}
                />
                <Route path="/expertis/branding" element={<Branding />} />
                <Route
                  path="/expertis/web-development"
                  element={<WebDevelopment />}
                />
                <Route
                  path="/expertis/social-media"
                  element={<SocialMedia />}
                />
                <Route
                  path="/expertis/content-writing"
                  element={<ContentWriting />}
                />
                <Route
                  path="/expertis/digital-media"
                  element={<DigitalMedia />}
                />
                <Route path="/blank" element={<Blank />} />
                <Route path="*" element={<Error />} />
              </Routes>
              {/* <GoToTop /> */}
              <Footer />
            </BrowserRouter>
          </div>
        </ParallaxProvider>
      </div>
    </>
  );
}

export default App;
