const ImgServicesData = [
  {
    id: 1,
    imgSrc: require("../../../assets/images/services/slider/BJP_Bharatiya_Janata_Party_Mumbai_Taj_Hotel.jpg"),
  },
  {
    id: 2,
    imgSrc: require("../../../assets/images/services/slider/Beautiful_House_in_india_Book_architect_Interior_Exterior.jpg"),
  },
  {
    id: 3,
    imgSrc: require("../../../assets/images/services/slider/Book_Launch_Writer_Blog.jpg"),
  },
  {
    id: 4,
    imgSrc: require("../../../assets/images/services/slider/Cleaning_By_Leslea_Tarrot_card_Healing_reiki_Energy_exchange.jpg"),
  },
  {
    id: 5,
    imgSrc: require("../../../assets/images/services/slider/Dj_Gida_Almost_Human_Medici_Bombay_cocktail_Bar_Music_EDM_trance.jpg"),
  },
  {
    id: 6,
    imgSrc: require("../../../assets/images/services/slider/Gs_Breeze_Air_Freshness_Oil_Burner_Fragrance_Car_Office.jpg"),
  },
  {
    id: 7,
    imgSrc: require("../../../assets/images/services/slider/Hotel_German_Palace_Bangali_Food_Festival_Sweet_Ahmedabad.jpg"),
  },
  {
    id: 8,
    imgSrc: require("../../../assets/images/services/slider/Meddy_bakery_Bread_Pau_Khari_toast_Cake_Cream.jpg"),
  },
  {
    id: 9,
    imgSrc: require("../../../assets/images/services/slider/Mitra_s_Perfume_Men_Woman_Girl_Air_Freshness.jpg"),
  },
  {
    id: 10,
    imgSrc: require("../../../assets/images/services/slider/Navin_Prabakar_Best_Live_Comedian_stand_up_Corporate.jpg"),
  },
  {
    id: 11,
    imgSrc: require("../../../assets/images/services/slider/Phullara_Earrings_Alia_Bhutt_Rings_Chokers_Shilpa_shetty.jpg"),
  },
  {
    id: 12,
    imgSrc: require("../../../assets/images/services/slider/Ritika_Bajaj_Director_Producer_Serial_Award_Film_Short_Film_TV_ad.jpg"),
  },
];
export default ImgServicesData;
