const clientData = [
  {
    id: 1,
    imgSrc: require("../../../assets/images/client_logo/Mitticool.png"),
    title: "HTML",
    style: "shadow-orange-500",
  },
  {
    id: 2,
    imgSrc: require("../../../assets/images/client_logo/Tait_It_computer_product.png"),
    title: "CSS",
    style: "shadow-blue-500",
  },
  {
    id: 3,
    imgSrc: require("../../../assets/images/client_logo/eagle_express_travel.png"),
    title: "JavaScript",
    style: "shadow-yellow-500",
  },
  {
    id: 4,
    imgSrc: require("../../../assets/images/client_logo/gida_dj_music.png"),
    title: "React",
    style: "shadow-blue-600",
  },
  {
    id: 5,
    imgSrc: require("../../../assets/images/client_logo/Harmano_Music_Mobile_Product.png"),
    title: "Tailwind",
    style: "shadow-sky-400",
  },
  {
    id: 6,
    imgSrc: require("../../../assets/images/client_logo/indian_story_teller.png"),
    title: "Next JS",
    style: "shadow-sky-400",
  },
  {
    id: 7,
    imgSrc: require("../../../assets/images/client_logo/Kalrashukla - IIT JEE Coaching Class.png"),
    title: "GraphQL",
    style: "shadow-pink-400",
  },
  {
    id: 8,
    imgSrc: require("../../../assets/images/client_logo/nilam_fashion.png"),
    title: "GitHub",
  },
  {
    id: 9,
    imgSrc: require("../../../assets/images/client_logo/pawa_insurance.png"),
    title: "GitHub",
  },
  {
    id: 10,
    imgSrc: require("../../../assets/images/client_logo/prima_terra_builder_homes.png"),
    title: "GitHub",
  },
  {
    id: 11,
    imgSrc: require("../../../assets/images/client_logo/siddha_builder_home.png"),
    title: "GitHub",
  },
  {
    id: 12,
    imgSrc: require("../../../assets/images/client_logo/the_wine_leaf_resturant.png"),
    title: "GitHub",
  },
  {
    id: 13,
    imgSrc: require("../../../assets/images/client_logo/new/Enfuse.png"),
    title: "GitHub",
  },
  {
    id: 14,
    imgSrc: require("../../../assets/images/client_logo/new/G's Breeze.png"),
    title: "GitHub",
  },
  {
    id: 15,
    imgSrc: require("../../../assets/images/client_logo/new/Hearfit.png"),
    title: "GitHub",
    styleData: "brightness(0) invert(1)",
  },
  {
    id: 16,
    imgSrc: require("../../../assets/images/client_logo/new/MMPL.png"),
    title: "GitHub",
  },
  {
    id: 17,
    imgSrc: require("../../../assets/images/client_logo/new/Namah.png"),
    title: "GitHub",
  },
  {
    id: 18,
    imgSrc: require("../../../assets/images/client_logo/new/Om Moldings.png"),
    title: "GitHub",
  },
  {
    id: 19,
    imgSrc: require("../../../assets/images/client_logo/new/Ramos.png"),
    title: "GitHub",
    styleData: "brightness(0) invert(1)",
  },
  {
    id: 20,
    imgSrc: require("../../../assets/images/client_logo/new/Rupiya.png"),
    title: "GitHub",
    styleData: "brightness(0) invert(1)",
  },
  {
    id: 21,
    imgSrc: require("../../../assets/images/client_logo/new/S-Mart.png"),
    title: "GitHub",
  },
  {
    id: 22,
    imgSrc: require("../../../assets/images/client_logo/new/Tessoy.png"),
    title: "GitHub",
  },
  {
    id: 23,
    imgSrc: require("../../../assets/images/client_logo/new/Zafar's Tea.png"),
    title: "GitHub",
    styleData: "brightness(0) invert(1)",
  },
];
export default clientData;
