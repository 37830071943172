const testimonialData = [
  {
    id: 1,
    imgSrc: require("../../../assets/images/Testimonial/MR_GAURAV_SATAV.png"),
    tname: "Mr. Gaurav Satav",
    designation: "Founder of Seven Billion Meditator",
    videoUrl: "https://www.youtube.com/embed/5bEa5aWHHz8?si=RQR7tkHJD67L8jx0",
  },
  {
    id: 2,
    imgSrc: require("../../../assets/images/Testimonial/MS_RITIKA_BAJAJ.png"),
    tname: "Ms. Ritika Bajaj",
    designation: "Founder of Indian Story Tellers",
    videoUrl: "https://www.youtube.com/embed/Kll4SBj7yLc?si=m40nqzpVdrg3rxph",
  },
  {
    id: 3,
    imgSrc: require("../../../assets/images/Testimonial/MR_JAWALA_SINGH.png"),
    tname: "Mr. Jawala Singh",
    designation: "Director of Mmc",
    videoUrl: "https://www.youtube.com/embed/0sB7o1CPLVs?si=7MBiLXPCT8SvQG4h",
  },
  {
    id: 4,
    imgSrc: require("../../../assets/images/Testimonial/MS_MAIRA_DOSHI.png"),
    tname: "Ms. Maira Doshi",
    designation: "Actress",
    videoUrl: "https://www.youtube.com/embed/ax6bIs7kpRc",
  },
  {
    id: 5,
    imgSrc: require("../../../assets/images/Testimonial/MR_MAHENDRA_JANGIR.png"),
    tname: "Mr. Mahendra Jangir",
    designation: "Founder & Ceo of Harmano",
    videoUrl: "https://www.youtube.com/embed/N3D_13mCy74",
  },
  {
    id: 6,
    imgSrc: require("../../../assets/images/Testimonial/MS_NIKITA_RATHOD.png"),
    tname: "Ms. Nikita Rathod",
    designation: "Owner of Arr Creative",
    videoUrl: "https://www.youtube.com/embed/PieAU9tdEVg",
  },
  {
    id: 7,
    imgSrc: require("../../../assets/images/Testimonial/MR_SUNIL_THARIANI.png"),
    tname: "Mr. Sunil Thariani",
    designation: "Director of Tait",
    videoUrl: "https://www.youtube.com/embed/E2ApxCnjxYM",
  },
  {
    id: 8,
    imgSrc: require("../../../assets/images/Testimonial/MR_HARSH_SHAH.png"),
    tname: "Mr. Harsh Shah",
    designation: "Owner of Namah Construction",
    videoUrl: "https://www.youtube.com/embed/YxfwjaX9UYQ",
  },
  {
    id: 9,
    imgSrc: require("../../../assets/images/Testimonial/MR_SHREEPAL_SHAH.png"),
    tname: "Mr. Shreepal Shah",
    designation: "Director of Marshal Logistics",
    videoUrl: "https://www.youtube.com/embed/1ZiSEC_7urQ",
  },
  {
    id: 10,
    imgSrc: require("../../../assets/images/Testimonial/MR_SAHIL_SODHA.png"),
    tname: "Mr. Sahil Sodha",
    designation: "Director of Seven Seas Global",
    videoUrl: "https://www.youtube.com/embed/MNS1VpZ5zBk",
  },
  {
    id: 11,
    imgSrc: require("../../../assets/images/Testimonial/MR_KAPIL_JAIN.png"),
    tname: "Mr. Kapil Jain",
    designation: "Director of Samarpan",
    videoUrl: "https://www.youtube.com/embed/IF3SyFEECWo",
  },
  {
    id: 12,
    imgSrc: require("../../../assets/images/Testimonial/MR_SUNIL_SODHA.png"),
    tname: "Mr. Sunil Sodha",
    designation: "M.d. Seven Seas Global Pvt.ltd",
    videoUrl: "https://www.youtube.com/embed/V3LlVNID72Q",
  },
];
export default testimonialData;
