import React from 'react'
import ContactSecOne from './components/pagesSection/contact/contactSecOne'

const Contact = () => {
    return (
        <>
            <ContactSecOne />
        </>
    )
}

export default Contact